import { Helmet } from 'react-helmet';
import Layout from '../../components/layout';
import React from 'react';
import Thankyou from '../../components/webinar/thankyou';
import { WebinarCookieManagementContextProvider } from '../../context/webinarCookiesManagementContext';
import { WebinarFormValidationContextProvider } from '../../context/webinarFormValidationContext';

const index = () => {
  return (
    <Layout header footer stickyFooter={false}>
      <Helmet>
        <title>General Contact</title>
        <meta name='og:description' content='We Would Love to Hear from You' />
        <meta name='description' content='We Would Love to Hear from You' />
        <meta name='og:title' content='General Contact' />
        <meta name='title' content='General Contact' />
      </Helmet>
      <WebinarFormValidationContextProvider>
        <WebinarCookieManagementContextProvider>
          <Thankyou
            content={{
              header: 'We Would Love to Hear from You!',
              description: [
                'We understand how confusing Medicare can be. Thats why we are excited to be a one-stop education source for all things Medicare . If you have questions or need help with your coverage options, our team is available to help guide you. Our seemingly endless online resources of free webinars, Medicare 101 programs, and web articles continue to be a reliable, trusted source for your Medicare needs.',
                'Additionally, our team is available to help with researching, comparing, and enrolling in plans in your area. Just give us a call at the number below to speak with an agent today.',
              ],
            }}
            isThankYou={false}
          />
        </WebinarCookieManagementContextProvider>
      </WebinarFormValidationContextProvider>
    </Layout>
  );
};

export default index;
